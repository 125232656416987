<template>
    <main>
        <data-mobil :datas="datas"></data-mobil>
    </main>
</template>
<script>
    import DataMobil from './DataMobil.vue'
    export default {
        components: {
            DataMobil
        },
        data: () => ({
            datas: []
        }),
        methods: {
            async getMyMobil() {
                    if (this.isSales){
                    const vehicles = await this.$store.dispatch('gudang/getData', {
                        jenis: 2,
                        karyawan_id: this.user.karyawan.id
                    })
                    this.datas = vehicles
                    this.$store.commit('gudang/SET_GUDANG_MOBIL', vehicles)
                } else {
                    const vehicles = await this.$store.dispatch('gudang/getData', {
                        jenis: 2
                    })
                    this.datas = vehicles
                    this.$store.commit('gudang/SET_GUDANG_MOBIL', vehicles)
                }
                // if (isAdminGudang || isSPV || isOwner){
                //     const vehicles = await this.$store.dispatch('gudang/getData', {
                //         jenis: 2,
                //     })
                //     this.datas = vehicles
                // }
            }
        },
        created() {
            this.getMyMobil()
        }
    }
</script>
